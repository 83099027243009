// Post listing
.posts {
  // @extend %underline-util;

  .post-teaser {
    @extend %padding-regular;
    width: 100%;
    margin-bottom: 0;
    display: inline-block;
  }

  .excerpt {
    @extend %link-hover;
    margin-top: 1em;
  }
}
// Pagination
.pagination .button {
  margin: 0 1.5em;
  i {
    vertical-align: middle;
  }
}
.pagination {
  padding: $padding-small $padding-large 0 $padding-large;
  text-align: center;
}

.home {
  @extend %padding-regular;
  @extend %link-hover;
  
  h1 {
    margin-top: 0;
  }

  .container {
    display: flex;
    flex-direction: row;
    // align-items: center;
    // justify-content: center;
    .avatar img {
      min-width: 225px;
      max-width: 225px;
    }
  
    .blurb {
      padding-right: 50px;
      // padding-top: 40px;
    }
  
    .avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .social-icons {
      list-style-type: none;
      display: flex;
      font-size: 1.3em;

      > * {
        margin: 4px 2px 0 2px;
      }
    }
  }

  @media (max-width: $md) {
    
    

    .title {
      display: none;
    }

    .container {
      flex-direction: column-reverse;
      align-items: center;
  
      .avatar img {
        min-width: 175px;
        max-width: 175px;
      }
  
      .blurb {
        padding: 0;
      }
    }
  }
}