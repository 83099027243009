.site-header {
  @extend %padding-small;
  background: $brand-color;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: $font-family-headings;

  @media (max-width: $md) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
  }

  a {
    color: $header-link-color;
  }

  .site-title {
    font-weight: bold;
    font-size: 2em;
  }

  @extend %underline-util;

  .site-nav {
    display: flex;

    @media (max-width: $lg) {
      flex-direction: column;
      align-items: flex-end;
    }
    @media (max-width: $md) {
      align-items: center;
      text-align: center;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      display: inline;
      margin-right: 0.4em;

      .page-link {
        color: $text-color;
        font-size: 1.7em;

        margin: 2px 10px;
        @media (max-width: $sm) {
          margin: 2px 5px;
          font-size: 1.1em;
        }
        @media (max-width: $xs) {
          font-size: 1em;
        }
      }
    }
  }
}
