// External
@import 'external/reset';
@import 'external/syntax';

// Base
@import 'base/variables';
@import 'base/global';
@import 'base/utility';

// Posts
@import 'layouts/posts';
@import 'layouts/index';

// Partials
@import 'includes/header';
@import 'includes/footer';
