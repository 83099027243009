// Mix-ins
%padding-x-small {
  padding: 1.5rem 3rem;
  @media (max-width: $lg) {
    padding: $padding-x-small $padding-x-small;
  }
}
%padding-small {
  padding: $padding-x-small / 1.75 $padding-x-small * 2;
  @media (max-width: $lg) {
    padding: $padding-x-small $padding-x-small;
  }
  @media (max-width: $sm) {
    padding: $padding-x-small $padding-x-small $padding-xx-small $padding-x-small;
  }
}
%padding-regular {
  padding: $padding-xx-small $padding-large $padding-xx-small $padding-large;
  
  @media (max-width: $xl) {
    // padding: 0 $padding-large / 1.6 $padding-small $padding-large / 1.6;

    padding: 0 $padding-medium $padding-small $padding-medium;
  }

  @media (max-width: $md) {
    padding: 0 $padding-small $padding-small $padding-small;
  }

  @media (max-width: $sm) {
    padding: 0 $padding-small $padding-small $padding-small;
  }
}
%link-hover {
  a:hover {
    text-decoration: underline;
    color: darken($link-color, 15%);
  }
  a {
    font-weight: 500;
  }
}
// Buttons
.button {
  border-radius: 0.3em;
  border: 1px solid;
  display: inline-block;
  margin: 1em 0;
  padding: 0.5em 0.75em;
}
a.button:hover {
  background: $link-color;
  border: 1px solid $link-color;
  color: $background-color;
  text-decoration: none;
}
// States
.disabled {
  opacity: 0.7;
}

%underline {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -5px;
  background: #21D4A1;
  height: 5px;
}

%underline-active {
  @extend %underline;
  left: 0;
  right: auto;
  width: 100%;
  // background: #F25F5C;
  // animation: colorfade .5s ease-in-out;
}

// @keyframes colorfade {
//   from {background: #21D4A1;}
//   to {background: #F25F5C;}
// }

%underline-animation {
  @extend %underline;
  right: 0;
  width: 0;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

%underline-after {
  @extend %underline;
  right: 0;
  width: 0;
}

%underline-util {
  .underline {
    display: inline;
    position: relative;
    overflow: hidden;
  }
  
  .active:after {
    @extend %underline-active;
  }
  
  :not(.active).underline:after {
    @extend %underline-animation;
  }
  .underline:hover:after,
  .underline:focus:after,
  .underline:active:after {
    left: 0;
    right: auto;
    width: 100%;
  }
}