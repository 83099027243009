article,
.comments {
  @extend %padding-regular;
  // border-bottom: 1px solid $border-color;
  float: left;
  width: 100%;
}

.title {
  margin-top: 0;
}

article {
  header {
    margin-bottom: 6%;
    text-align: center;
  }
  @extend %link-hover;
  .footnotes {
    font-size: 0.9em;
  }
}
header {
  h1 {
    margin: 0;
  }
  .meta {
    color: rgba($text-color, .5);
    font-size: 0.9em;
    letter-spacing: 0.1em;
    margin: 0;
    text-transform: uppercase;
  }
}

// Post navigation
#post-nav {
  width:100%;
  // border-bottom: 1px solid $border-color;
  display: flex;
  float: left;

  a, .page-title {
    display: inline-block;
  }

  .page-title {
    font-size: 1.2em;
    margin-bottom: 1em;
    width: 100%;
  }

  a {
    padding: 2em 3em;
    // border: 1px solid rgba(255, 255, 255, 0);
    text-align: center;
    width: 50%;
  }
  a:hover {
    @extend a.button:hover;
  }

  i {
    vertical-align: middle;
  }
}

.highlighter-rouge {
  background-color: #f7f7f7;

  pre {
    font-size: .9em;
    padding: .1em;
  }
}

code {
  margin: 0 .3em;
  font-size: .95em;
}
