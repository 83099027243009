// https://coolors.co/ffffff-000000-797d81-5011e4-21d4a1-f25f5c
// https://coolors.co/ffffff-000000-797d81-ff5f5c-21d4a1-f25f5c

// Typography
@font-face {
    font-family: "Headings";
    src: url("fonts/headings.woff") format('woff');
    font-weight: bold;
}

// $font-family-main: 'Cardo', serif;
$font-family-main: 'Helvetica Neue', sans-serif;
$font-family-headings: 'Headings', sans-serif;
$font-size: 1.15em;

// Padding
$padding-large: 20%;
$padding-medium: 10%;
$padding-small: 5%;
$padding-x-small: 3%;
$padding-xx-small: 1%;

// Brand colours
$brand-color: #fff;
$background-color: #fff;
$border-color: rgba(0, 0, 0, 0.1); // rgba recommended if using feature images

// Typography colours
$text-color: #383838;
$link-color: #FF5F5C;
// $link-color: #5011e4;
$selection-color: #E0E0E1;

// Header colours
$header-link-color: #383838;

// Feature image for articles
$feature-image-text-color: #fff;
$feature-image-size: cover; // options include "cover", "contain", "auto"

// Header description box
$header-desc-background-color: #3498db;
$header-desc-text-color: #FFF;

// Responsive breakpoints
$xs: 376px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;